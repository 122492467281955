import React, {useState} from "react";
import {NavLink, withRouter, Link} from 'react-router-dom';
import {Waypoint} from 'react-waypoint';
import Lotties from '../components/Lottie';

//Import SVG
import {ReactComponent as Outline} from '../assets/outline-hero.svg';
//Import Images
import HeroImg from '../assets/prime-cut.jpg';
import HeroWebp from "../assets/prime.jpeg";
//Import Component
import Wasmachenwir from "../components/wasmachenwir";
import Footer from "../components/Footer";

function Home() {

    let [renderLottie, setRenderLottie] = useState(false);

    return (
        <>
          <div className="container">
              <img className="heroimg" src={HeroWebp} alt="primetower"></img>
            <div className="wrapper">
              <div class="home">
                  <h2 className="textHero">
                  Wir helfen Unternehmen den Dialog <br></br> zu ihren Kunden zu finden
                  </h2>
                  <h5 className="textHero">
                      Beratung. Branding. Content. Service.
                  </h5>
              </div>
            </div>
            <div className="wrapper">
              <div class="home flexrow">
                  <h2>
                  Erfolg in der digitalen Welt
                  </h2>
                  <div className="width-50">
                      <h5>
                      Die Welt befindet sich in einem stetigen Wandel. Gerade in der heutigen Zeit ist es umso wichtiger Dienstleistungen und Produkte Online anzubieten. Wir haben es uns zur Aufgabe gemacht, nicht bloss eine Lösung für die Masse anzubieten, sondern nehmen uns für jedes Unternehmen die Zeit, um das Beste rauszuholen.
                      </h5>
                      <h5>
                      Aussehen wie alle anderen ist nicht gut fürs Geschäft. Stechen Sie mit einzigartigen Lösungen aus der Menge heraus und schenken Sie Ihren Kunden ein unvergessliches Erlebnis!
                      </h5>
                  </div>
                  <div className="Lottie">
                  <Waypoint onEnter={()=>setRenderLottie(true)} />
                    { renderLottie && <Lotties /> }
                  </div>
              </div>
            </div>
          </div>
          <div className="wrapper">
                <Wasmachenwir />
            </div>
          <div className="container">
              <div className="wrapper">
                    <div className="two-rows">
                        <div className="two-columns">
                            <h2 className="gradientText">Arbeite <br></br>mit uns!</h2>
                        </div>
                        <div className="two-columns">
                            <h5>
                            Wir sind grossartige Zuhörer. Erzählen Sie uns von Ihren Bedürfnissen. Gemeinsam erschaffen wir etwas wundervolles!   
                            </h5>
                            <h5 className="sagHallo"><Link to="/kontakt">Sag Hallo!</Link></h5>
                        </div>
                    </div>
              </div>
          </div>
          <Footer />
      </>
    );
  }

  export default Home;