import React from 'react';
import {Link} from 'react-router-dom';



const Wasmachenwir = () => {
    return(
        <div className="fullwidth-container">
            <div className="animText">
                <span>Strategien Webdesign CMS Prozessoptimierung Fotografie Updates E-Commerce Copywriting SEO Appentwicklung</span>
            </div>
            <div className="container">
            <div className="title-dark">
                <h2>Was machen wir?</h2>
            </div>
            <div className="wrapper">
                <div className="inner-container">
                    <div className="title">
                        <h3>Beratung</h3>
                    </div>
                    <div className="desc">
                        <h3>Strategien</h3>
                        <h3>Unternehmensberatung</h3>
                        <h3>Prozessoptimierung</h3>
                    </div>
                </div>
                <div className="inner-container">
                    <div className="title">
                        <h3>Branding</h3>
                    </div>
                    <div className="desc">
                        <h3>Webdesign</h3>
                        <h3>Appentwicklung</h3>
                        <h3>E-Commerce</h3>
                        <h3>CMS</h3>
                        <h3>UX/UI</h3>
                        <h3>Brand Identity</h3>
                        <h3>SEO</h3>
                        <h3>Social Media</h3>
                        <h3>Online-Ads</h3>
                    </div>
                </div>
                <div className="inner-container">
                    <div className="title">
                        <h3>Content</h3>
                    </div>
                    <div className="desc">
                        <h3>Fotografie</h3>
                        <h3>Video Produktion</h3>
                        <h3>Texte</h3>
                        <h3>Copywriting</h3>
                    </div>
                </div>
                <div className="inner-container">
                    <div className="title">
                        <h3>Service</h3>
                    </div>
                    <div className="desc">
                        <h3>Hosting</h3>
                        <h3>Updates</h3>
                        <h3>Unterhalt</h3>
                    </div>
                </div>
                <div className="container-text-wmw">
                    <h4>Zu wenig Fleisch am Knochen?</h4>
                    <h4><Link to='/dienstleistungen'>Erfahren Sie hier was wir genau machen</Link></h4>
                </div>
            </div>
            </div>
            
        </div>
    )
}

export default Wasmachenwir;