import React from "react";
import {Link} from 'react-router-dom';

//Import SVG
import {ReactComponent as Graph} from '../assets/graph.svg';

//Import Component
import Footer from "../components/Footer";

const Dienstleistungen = () => {
    return(
        <>
        <div className="container">
            <div className="section">
                <div className="inner-section height100">
                    <h1>Dienstleistungen</h1>
                    <h4>Unsere Kompetenzen im digitalen Bereich ermöglichen Ihnen mehr Kunden zu erreichen und den Absatz zu steigern. Wir optimieren Ihre Geschäftsprozesse und verbinden diese mit digitalen Produkten. Um das optimale Ergebnis zu erreichen basieren unsere Dienstleistungen auf den vier Pfeilern Beratung, Branding, Content und Service.</h4>
                </div>
            </div>
        </div>
        <div className="section">
                <div className="inner-section gradient">
                    <div className="graph">
                        <Graph />
                    </div>
                </div>
            </div>
        
        <div className="container">
            <div className="wrapper">
                <div className="service">
                    <div className="desc">
                        <h2>Beratung</h2>
                        <h3>Wir helfen Unternehmen mit neuen Kunden in Kontakt zu treten. Durch die Verknüpfung von Beratung, neuster Technologien, Design, Content und Storytelling lassen wir Unternehmen im besten Licht erstrahlen. Unser Workflow basiert in erster Linie auf der Feststellung der Bedürfnisse und der Zielgruppe. Nach der Analyse schlagen wir die beste Lösung vor.  Auf dieser Basis erarbeiten wir ein schönes, anziehendes Design. </h3>
                    </div>
                    <div className="bullet-points">
                        <h3>Strategien</h3>
                        <h3>Prozessoptimierung</h3>
                        <h3>Unternehmensberatung</h3>
                    </div>
                </div>
                <div className="service">
                    <div className="desc">
                        <h2>Branding</h2>
                        <h3>Digital Branding ist unsere Stärke. Wir sind in der Lage Projekte von Grundauf zu kreieren, oder machen Redesigns oder Liftings bestehender Marken. Hier verbinden wir verschiedene Kompetenzen zusammen, von erstellen einer Webseite oder Online-Shop über UX/UI Design (damit Ihre Kunden schnell und einfach die gewünschten Informationen finden), dem kreieren oder Konkretisierung Ihrer Unternehmens-Identität, bis hin zur Suchmaschinenoptimierungen (Google) und Online-Werbung. Dabei denken wir weiter als typische Branding-Houses. Wir wissen, dass Branding auch Form & Funktion Ihres Online-Auftrittes beinhaltet. Während der Arbeit an Ihrer Marke denken wir von Anfang an daran, wie sich Ihr Unternehmen auf Sozialen Medien, auf dem Smartphone, Tablet und auf dem Desktop widerspiegeln soll.</h3>
                    </div>
                    <div className="bullet-points">
                        <h3>Webdesign</h3>
                        <h3>Appentwicklung</h3>
                        <h3>E-Commerce</h3>
                        <h3>CMS</h3>
                        <h3>UX/UI</h3>
                        <h3>Brand Identity</h3>
                        <h3>SEO</h3>
                        <h3>Social Media</h3>
                        <h3>Online-Ads</h3>
                    </div>
                </div>
                <div className="service">
                    <div className="desc">
                        <h2>Content</h2>
                        <h3>"Content is King" - dieses Zitat von 1996 vom Microsoft Gründer Bill Gates gilt bis heute. Wir bei Prepublic nehmen uns dies zu Herzen. Fehlen ausdrucksstarke Fotos & Videos, oder Texte, welche Emotionslos Produkte/Dienstleistungen beschreiben, werden Kunden gegenüber Ihrer Marke genau gleich reagieren - Ohne Emotionen. Egal ob für Social Media, oder den Online-Auftritt - unser Team wird für den nötigen Wow-Effekt sorgen. </h3>
                    </div>
                    <div className="bullet-points">
                        <h3>Fotografie</h3>
                        <h3>Video Produktion</h3>
                        <h3>Texte</h3>
                        <h3>Copywriting</h3>
                    </div>
                </div>
                <div className="service">
                    <div className="desc">
                        <h2>Service</h2>
                        <h3>Nachdem ein Online-Auftritt erstellt worden ist, muss dieser im WWW landen. Dank unserer Infrastruktur, welche sich in der Schweiz befindet und unserer optimierten Vorgehensweise, können wir Webseiten und anderen Dienste sicher und schnell der ganzen Welt zur Verfügung stellen. Unser Antiviren & Antimalware-Schutz hält bösewillige Machenschaften vor Ihren Daten fern. Um immer einen Schritt voraus zu sein machen wir regelmässige Updates, nicht nur von unseren Servern, sondern auch von Ihrem Webauftritt. Haben Sie Inhalte, welche Sie regelmässig auf Ihrem Online-Dienst erweitern möchten? Gerne übernehmen wir den Unterhalt, damit Sie sich auf das Kerngeschäft konzentrieren können.</h3>
                    </div>
                    <div className="bullet-points">
                        <h3>Hosting</h3>
                        <h3>Updates</h3>
                        <h3>Unterhalt</h3>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
        </>
    )
}

export default Dienstleistungen;