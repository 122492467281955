import React from 'react';

//Import Components
import Footer from "../components/Footer";
import ContactForm from "../components/contactForm";

const Kontakt = () => {
    return(
        <>
        <div className="container">
            <div className="section">
                <div className="inner-section height100">
                    <h1>Kontakt</h1>
                    <h4>Ready, set, go! Wir freuen uns Sie kennen zu lernen und das nächste spannende Projekt umsetzen zu können.</h4>
                    <div className="kontakt-info">
                        <div className="kontakt">
                            <h2>Telefon</h2>
                            <h3>079 254 64 44</h3>
                        </div>
                        <div className="kontakt">
                            <h2>E-Mail</h2>
                            <h3>hallo@prepublic.ch</h3>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Footer />
        </>
    )
}

export default Kontakt;