import React from 'react';

//Import images
import placeholder from "../assets/placeholder.jpg";

//Import Components
import Footer from "../components/Footer";


const Agentur = () => {
    return(
       <> 
       <div className="container">
            <div className="section">
                <div className="inner-section height100">
                    <h1>Agentur</h1>
                    <h4>Wir arbeiten für Unternehmen - und noch lieber mit Unternehmen</h4>
                </div>
            </div>
        </div>
        <div className="section">
                <div className="inner-section gradient">
                    <div className="features">
                        <div className="feature">
                            <h3>4 Jahre</h3>
                            <h5>an Ihrer Seite</h5>
                        </div>
                        <div className="feature">
                            <h3>200+</h3>
                            <h5>abgeschlossene Projekte</h5>
                        </div>
                        <div className="feature">
                            <h3>100%</h3>
                            <h5>unabhängig</h5>
                        </div>
                    </div>
                </div>
            </div>
        <div className="container">
            <div className="team-title">
                <h2>Team</h2>
            </div>
            <div className="wrapper-team">
                <div className="team-img">
                    <img src={placeholder} alt="placeholder"></img>
                </div>
                <div className="desc-team">
                    <h3>Charlie Riederer</h3>
                    <h5>Leiter und Berater von Prepublic. Er behält alle Projekte und Termine im Überblick. Als Velo-Aficionado und Züri-Kind bleibt keine Ecke und Kante vor ihm verborgen.</h5>
                </div>
            </div>
            <div className="wrapper-team team-reverse">
                <div className="team-img">
                    <img src={placeholder} alt="placeholder"></img>
                </div>
                <div className="desc-team">
                    <h3>Jasmin</h3>
                    <h5>Den Moment im besten Licht einzufangen ist ihre Leidenschaft.</h5>
                </div>
            </div>
            <div className="wrapper-team">
                <div className="team-img">
                    <img src={placeholder} alt="placeholder"></img>
                </div>
                <div className="desc-team">
                    <h3>Sebastian Sowinski</h3>
                    <h5>Designer und Digital-Consultant. Beigeistert von neuen Technologien erweitert er ständig sein Repertoire, um die Ideale Lösung zu finden. </h5>
                </div>
            </div>
        </div>
        <Footer />
       </>
    )
}

export default Agentur;