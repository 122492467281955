import React from "react";
//Import Route from React Router DOM
import {Route, withRouter} from 'react-router-dom';

import "./styles/App.scss";

//Import Components from Components
import Header from "./components/Header";

//Import Pages from Pages
import Home from "./pages/home";
import Dienstleistungen from "./pages/dienstleistungen";
import Agentur from "./pages/agentur";
import Kontakt from "./pages/kontakt";

//Defining Routes for React Router DOM
const routes = [
  {path: '/', name: 'Home', Component: Home},
  {path: '/dienstleistungen', name: 'Dienstleistungen', Component: Dienstleistungen},
  {path: '/agentur', name: 'Agentur', Component: Agentur},
  {path: '/kontakt', name: 'Kontakt', Component: Kontakt}
];

function App() {
  return (
    <>
    <Header />
    {routes.map(({path, Component}) => (
      <Route key={path} exact path={path}>
        <Component />
      </Route>
    ))}
    </>
  );
}


export default withRouter(App);
