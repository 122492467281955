import React from 'react';

const Footer = () => {
    return(
        <>
        <div className="footerBorder"></div>
        <div className="container">
            <div className="footer-wrapper">
                <div className="footer-column">
                    <h4>Prepublic <br></br> Hohlstrasse 43 <br></br> 8005 Zürich</h4>
                </div>
                <div className="footer-column">
                    <h4>Website made with 💗</h4>
                </div>
                <div className="footer-column">
                    <h4> 📧 hallo@prepublic.ch <br></br> 📱 079 254 64 44</h4>
                </div>
            </div>
        </div>
        </>
    )
}

export default Footer;