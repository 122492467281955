import React, { Component } from 'react';
import Lottie from 'react-lottie';
import animationData from '../assets/ball-lottie.json';
class Lotties extends Component {
render() {
const defaultOptions = {
loop: false,
autoplay: true,
animationData: animationData,
rendererSettings: {
},
};
return (
<Lottie options={defaultOptions} />
);
}
}
export default Lotties;